var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('add-bot-section',{staticClass:"add-bot-section",attrs:{"title":_vm.lang.botMaker.addBot.empathicEngine.title[_vm.languageSelected]}},[_c('template',{slot:"whole-w"},[_c('new-form-group',{attrs:{"label":_vm.lang.botMaker.addBot.engineSetting.noIntentTimesToTakeOver[
          _vm.languageSelected
        ],"tooltip":_vm.lang.botMaker.addBot.engineSetting.noIntentTimesToTakeOverTooltip[
          _vm.languageSelected
        ]}},[_c('vs-input',{attrs:{"data-test":"noIntentTimesToTakeOver","type":"number","min":0,"required":"","danger":_vm.validations.noIntentTimesToTakeOver,"danger-text":_vm.lang.botMaker.addBot.engineSetting.noIntentTimesToTakeOverError[
            _vm.languageSelected
          ]},on:{"keypress":function($event){_vm.isNumber($event)}},model:{value:(_vm.bot.noIntentTimesToTakeOver),callback:function ($$v) {_vm.$set(_vm.bot, "noIntentTimesToTakeOver", $$v)},expression:"bot.noIntentTimesToTakeOver"}})],1),_vm._v(" "),_c('new-form-group',{attrs:{"label":_vm.lang.botMaker.addBot.engineSetting.platformsNoIntentTimesToTakeOver[
          _vm.languageSelected
        ],"tooltip":_vm.lang.botMaker.addBot.engineSetting
          .platformsNoIntentTimesToTakeOverTooltip[_vm.languageSelected]}},[_c('multiselect',{attrs:{"data-test":"selectedPlatforms","placeholder":_vm.lang.botMaker.addBot.intentionTriggerSecondLevel.table
            .selectChannels[_vm.languageSelected],"tagPlaceholder":"","show-labels":false,"label":"name","track-by":"value","options":_vm.PLATFORMS,"multiple":true,"taggable":true,"limit":5},on:{"input":_vm.updateValueAction},model:{value:(_vm.selectedPlatforms),callback:function ($$v) {_vm.selectedPlatforms=$$v},expression:"selectedPlatforms"}})],1),_vm._v(" "),_c('new-form-group',{attrs:{"label":_vm.lang.botMaker.addBot.engineSetting.noIntentMessage2[_vm.languageSelected],"tooltip":_vm.lang.botMaker.addBot.engineSetting.noIntentMessage2Tooltip[
          _vm.languageSelected
        ]}},[_c('vs-input',{staticClass:"fill",attrs:{"data-test":"noIntentMessage2","required":"","danger":_vm.validations.noIntentMessage2,"danger-text":_vm.lang.botMaker.addBot.engineSetting.noIntentMessage2Error[
            _vm.languageSelected
          ]},model:{value:(_vm.bot.noIntentMessage2),callback:function ($$v) {_vm.$set(_vm.bot, "noIntentMessage2", $$v)},expression:"bot.noIntentMessage2"}})],1),_vm._v(" "),_c('new-form-group',{attrs:{"label":_vm.lang.botMaker.addBot.engineSetting.showEmotions[_vm.languageSelected]}},[_c('vx-slider',{attrs:{"data-test":"showEmotions"},model:{value:(_vm.bot.showEmotions),callback:function ($$v) {_vm.$set(_vm.bot, "showEmotions", $$v)},expression:"bot.showEmotions"}})],1),_vm._v(" "),_c('new-form-group',{attrs:{"label":_vm.lang.botMaker.addBot.engineSetting.negativeSentimentAction.text[
          _vm.languageSelected
        ],"tooltip":_vm.lang.botMaker.addBot.engineSetting.negativeSentimentAction.tooltip[
          _vm.languageSelected
        ]}},[_c('div',[_c('vs-checkbox',{staticClass:"timeout-action-checkbox timeout-action-checkbox-negative",attrs:{"data-test":"hasNegativeSentimentTakeover","color":"primary"},model:{value:(_vm.hasNegativeSentimentTakeover),callback:function ($$v) {_vm.hasNegativeSentimentTakeover=$$v},expression:"hasNegativeSentimentTakeover"}},[_vm._v("\n          "+_vm._s(_vm.lang.botMaker.addBot.engineSetting.timeoutActionExecute[
              _vm.languageSelected
            ])+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"flex mt-4 ml-6"},[(_vm.hasNegativeSentimentTakeover)?_c('vs-input',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(
              _vm.lang.botMaker.addBot.engineSetting.negativeSentimentAction
                .lessThanValue[_vm.languageSelected]
            ),expression:"\n              lang.botMaker.addBot.engineSetting.negativeSentimentAction\n                .lessThanValue[languageSelected]\n            ",modifiers:{"bottom":true}}],staticClass:"hasNegativeSentimentTakeover-number-input",attrs:{"data-test":"sentimentLessThan","type":"number","min":1,"max":5,"label":_vm.lang.botMaker.addBot.engineSetting.negativeSentimentAction
                .lessThan[_vm.languageSelected],"required":"","danger":_vm.validations.negativeSentiment.trigger.sentimentLessThan,"danger-text":_vm.lang.botMaker.addBot.engineSetting.negativeSentimentAction
                .lessThanError[_vm.languageSelected]},model:{value:(_vm.negativeSentimentOption.trigger.sentimentLessThan),callback:function ($$v) {_vm.$set(_vm.negativeSentimentOption.trigger, "sentimentLessThan", $$v)},expression:"negativeSentimentOption.trigger.sentimentLessThan"}}):_vm._e(),_vm._v(" "),(_vm.hasNegativeSentimentTakeover)?_c('vs-input',{staticClass:"hasNegativeSentimentTakeover-number-rep-input",attrs:{"type":"number","min":1,"label":_vm.lang.botMaker.addBot.engineSetting.negativeSentimentAction
                .repeat[_vm.languageSelected],"required":"","danger":_vm.validations.negativeSentiment.trigger.cant,"danger-text":_vm.lang.botMaker.addBot.engineSetting.negativeSentimentAction
                .cantError[_vm.languageSelected]},model:{value:(_vm.negativeSentimentOption.trigger.cant),callback:function ($$v) {_vm.$set(_vm.negativeSentimentOption.trigger, "cant", $$v)},expression:"negativeSentimentOption.trigger.cant"}}):_vm._e(),_vm._v(" "),(_vm.hasNegativeSentimentTakeover)?_c('vs-input',{staticClass:"fill",attrs:{"type":"text","required":"","label":_vm.lang.botMaker.addBot.engineSetting.negativeSentimentAction
                .message[_vm.languageSelected],"danger":_vm.validations.negativeSentiment.message,"danger-text":_vm.lang.botMaker.addBot.engineSetting.negativeSentimentAction
                .messageError[_vm.languageSelected]},model:{value:(_vm.negativeSentimentOption.positive.actions[0].args[0]),callback:function ($$v) {_vm.$set(_vm.negativeSentimentOption.positive.actions[0].args, 0, $$v)},expression:"negativeSentimentOption.positive.actions[0].args[0]"}}):_vm._e()],1)],1)])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }