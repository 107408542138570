<template>
  <add-bot-section
    :title="lang.botMaker.addBot.empathicEngine.title[languageSelected]"
    class="add-bot-section"
  >
    <template slot="whole-w">
      <!-- max errrors before derive -->
      <new-form-group
        :label="
          lang.botMaker.addBot.engineSetting.noIntentTimesToTakeOver[
            languageSelected
          ]
        "
        :tooltip="
          lang.botMaker.addBot.engineSetting.noIntentTimesToTakeOverTooltip[
            languageSelected
          ]
        "
      >
        <vs-input
          data-test="noIntentTimesToTakeOver"
          type="number"
          :min="0"
          required
          v-model="bot.noIntentTimesToTakeOver"
          @keypress="isNumber($event)"
          :danger="validations.noIntentTimesToTakeOver"
          :danger-text="
            lang.botMaker.addBot.engineSetting.noIntentTimesToTakeOverError[
              languageSelected
            ]
          "
        />
      </new-form-group>

      <!-- platforms that derive -->
      <new-form-group
        :label="
          lang.botMaker.addBot.engineSetting.platformsNoIntentTimesToTakeOver[
            languageSelected
          ]
        "
        :tooltip="
          lang.botMaker.addBot.engineSetting
            .platformsNoIntentTimesToTakeOverTooltip[languageSelected]
        "
      >
        <multiselect
          data-test="selectedPlatforms"
          v-model="selectedPlatforms"
          :placeholder="
            lang.botMaker.addBot.intentionTriggerSecondLevel.table
              .selectChannels[languageSelected]
          "
          tagPlaceholder=""
          :show-labels="false"
          label="name"
          track-by="value"
          :options="PLATFORMS"
          :multiple="true"
          :taggable="true"
          :limit="5"
          @input="updateValueAction"
        />
      </new-form-group>

      <!-- derive message -->
      <new-form-group
        :label="
          lang.botMaker.addBot.engineSetting.noIntentMessage2[languageSelected]
        "
        :tooltip="
          lang.botMaker.addBot.engineSetting.noIntentMessage2Tooltip[
            languageSelected
          ]
        "
      >
        <vs-input
          data-test="noIntentMessage2"
          class="fill"
          required
          v-model="bot.noIntentMessage2"
          :danger="validations.noIntentMessage2"
          :danger-text="
            lang.botMaker.addBot.engineSetting.noIntentMessage2Error[
              languageSelected
            ]
          "
        />
      </new-form-group>

      <!-- no intent action (minutes) -->
      <!-- <new-form-group
        :label="
          lang.botMaker.addBot.engineSetting.timeToTimeoutAction[
            languageSelected
          ]
        "
      >
        <vs-input
          data-test="inactivityTime"
          type="number"
          required
          :min="0"
          v-model="bot.inactivityTime"
          :danger="validations.inactivityTime"
          :danger-text="
            lang.botMaker.addBot.engineSetting.inactivityTimeError[
              languageSelected
            ]
          "
          @keypress="isDigit($event)"
        />
      </new-form-group> -->

      <!-- show emotions -->
      <new-form-group
        :label="
          lang.botMaker.addBot.engineSetting.showEmotions[languageSelected]
        "
      >
        <vx-slider data-test="showEmotions" v-model="bot.showEmotions" />
      </new-form-group>

      <!-- Action of deriving after a certain amount of messages in a row with negative sentiment -->
      <new-form-group
        :label="
          lang.botMaker.addBot.engineSetting.negativeSentimentAction.text[
            languageSelected
          ]
        "
        :tooltip="
          lang.botMaker.addBot.engineSetting.negativeSentimentAction.tooltip[
            languageSelected
          ]
        "
      >
        <div>
          <vs-checkbox
            data-test="hasNegativeSentimentTakeover"
            color="primary"
            class="timeout-action-checkbox timeout-action-checkbox-negative"
            v-model="hasNegativeSentimentTakeover"
          >
            {{
              lang.botMaker.addBot.engineSetting.timeoutActionExecute[
                languageSelected
              ]
            }}
          </vs-checkbox>
          <div class="flex mt-4 ml-6">
            <vs-input
              data-test="sentimentLessThan"
              v-if="hasNegativeSentimentTakeover"
              type="number"
              :min="1"
              :max="5"
              :label="
                lang.botMaker.addBot.engineSetting.negativeSentimentAction
                  .lessThan[languageSelected]
              "
              required
              class="hasNegativeSentimentTakeover-number-input"
              v-model="negativeSentimentOption.trigger.sentimentLessThan"
              :danger="validations.negativeSentiment.trigger.sentimentLessThan"
              :danger-text="
                lang.botMaker.addBot.engineSetting.negativeSentimentAction
                  .lessThanError[languageSelected]
              "
              v-tooltip.bottom="
                lang.botMaker.addBot.engineSetting.negativeSentimentAction
                  .lessThanValue[languageSelected]
              "
            />
            <vs-input
              v-if="hasNegativeSentimentTakeover"
              type="number"
              :min="1"
              :label="
                lang.botMaker.addBot.engineSetting.negativeSentimentAction
                  .repeat[languageSelected]
              "
              required
              class="hasNegativeSentimentTakeover-number-rep-input"
              v-model="negativeSentimentOption.trigger.cant"
              :danger="validations.negativeSentiment.trigger.cant"
              :danger-text="
                lang.botMaker.addBot.engineSetting.negativeSentimentAction
                  .cantError[languageSelected]
              "
            />
            <vs-input
              class="fill"
              v-if="hasNegativeSentimentTakeover"
              v-model="negativeSentimentOption.positive.actions[0].args[0]"
              type="text"
              required
              :label="
                lang.botMaker.addBot.engineSetting.negativeSentimentAction
                  .message[languageSelected]
              "
              :danger="validations.negativeSentiment.message"
              :danger-text="
                lang.botMaker.addBot.engineSetting.negativeSentimentAction
                  .messageError[languageSelected]
              "
            />
          </div>
        </div>
      </new-form-group>
    </template>
  </add-bot-section>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { PLATFORMS } from '@/constants/constants'

export default {
  name: 'EmpathicEngineSettings',
  components: {
    AddBotSection: () => import('./Components/AddBotSection.vue'),
    NewFormGroup: () => import('./Components/NewFormGroup.vue'),
    Multiselect: () => import('vue-multiselect')
  },
  watch: {
    'bot.nlu.culture'(newVal) {
      if (!this.isEditing) {
        const lang = newVal.split('-')[0]
        this.bot.noIntentMessage2 = this.lang.botMaker.addBot.generalSettings.form.noIntentMessage2[
          lang
        ]
      }
    },
    hasNegativeSentimentTakeover(newVal) {
      this.SET_NEGATIVE_SENTIMENT_TAKEOVER(newVal)
    }
  },
  props: {
    isEditing: Boolean
  },
  data() {
    return {
      PLATFORMS,
      selectedPlatforms: [],
      hasNegativeSentimentTakeover: false
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'newBotLanguage']),
    ...mapGetters('bots', [
      'bot',
      'validations',
      'hasNegativeSentimentOption',
      'negativeSentimentOption'
    ])
  },
  methods: {
    ...mapMutations('bots', ['SET_NEGATIVE_SENTIMENT_TAKEOVER']),
    updateValueAction(values) {
      if (values && values.length > 0) {
        this.bot.platformsNoIntentTimesToTakeOver = values.map(p => p.value)
      } else {
        this.bot.platformsNoIntentTimesToTakeOver = []
      }
    }
  },
  mounted() {
    this.hasNegativeSentimentTakeover = this.hasNegativeSentimentOption
    if (
      this.bot.platformsNoIntentTimesToTakeOver &&
      this.bot.platformsNoIntentTimesToTakeOver.length > 0
    ) {
      this.selectedPlatforms = PLATFORMS.filter(p =>
        this.bot.platformsNoIntentTimesToTakeOver.includes(p.value)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.add-bot-section {
  .hasNegativeSentimentTakeover-number-input {
    width: 270px;
    margin-right: 10px;
  }
  .hasNegativeSentimentTakeover-number-rep-input {
    width: 200px;
    margin-right: 10px;
  }
  .hasNegativeSentimentTakeover-number-span {
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
